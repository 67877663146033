/* SASS OVERRIDES (must load overrides before anything) */
$fa-font-path:   "../vendor/font-awesome/fonts";



/* 
  IMPORTS 
*/
  /* standard CSS imports (not compiled) */
/*  @import "../vendor/jquery-ui/jquery-ui.css";
  @import "../vendor/google/fonts/lato/lato-v11-latin.css";*/

  /* SCSS imports (compiled) */
  @import "node_modules/bootstrap/scss/bootstrap";
  @import "node_modules/font-awesome/scss/font-awesome";






/* Global utility styles
-------------------------------------------------- */
.hidden {
    display: none;
}



/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 40px;
}
.footer {
  /*position: absolute;*/
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  /*height: 40px;*/
  font-size: .75rem;
  text-align: center;
}

// Helpers

.hidden {
    display: none !important;
}

.width-100 {
    width: 100px !important;
}
.width-160 {
    width: 160px !important;
}
.width-200 {
    width: 200px !important;
}
.width-300 {
    width: 300px !important;
}
