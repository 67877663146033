@import "global";

/*
			BOOTSTRAP OVERRIDES
*/

body {
    font-family: 'Lato';
    margin-bottom: 0;
}

.fa-btn {
	margin-right: 6px;
}

.login_title h1{
    font-size:30px;
}

.login_logo{
    max-width:250px;
    margin:10px 0 20px 0;
}

.cancel{
    color: #EB4D5C;
    font-size:14px;
}


.page-header,
.page-header h1 {
    margin-top:20px;
    margin-bottom:30px;
    font-weight:700;
}

/* changed to -20 with new version of bootstrap */
.form-check-input {
	margin-left: -20px;
}

.table-striped.even-rows {
  tbody tr:nth-of-type(even) {
    background-color: $table-accent-bg;
  }
  tbody tr:nth-of-type(odd) {
    background-color: inherit;
  }
}

/*
            JQUERY UI OVERRIDES
*/

.ui-tabs-vertical { width: 55em; height: 100%; }
.ui-tabs-vertical .ui-tabs-nav { padding: .2em .1em .2em .2em; float: left; width: 12em; height: 100%;
    min-height: 200px;
    background: #FFF;
    padding: 0;
    border-bottom-right-radius: 0;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
}
.ui-tabs-vertical .ui-tabs-nav li { clear: left; width: 100%; border-bottom-width: 1px !important; border-right-width: 0 !important; margin: 0 -1px 0 0; }
.ui-tabs-vertical .ui-tabs-nav li:not(:first-child) { border-top-right-radius: 0; }
.ui-tabs-vertical .ui-tabs-nav li a { display:block; }
.ui-tabs-vertical .ui-tabs-nav li.ui-tabs-active { padding-bottom: 0; padding-right: .1em; border-right-width: 1px; }
.ui-tabs-vertical .ui-tabs-nav li.ui-state-active {
    background-color: #0275d8;
    border: 1px solid #0275d8;
}
.ui-tabs-vertical .ui-tabs-panel { padding: 1em; float: right; width: 40em;}





/*	Main nav	*/
.navbar-nav.navbar-right.with-margin {margin-right: 15px;}
.nav {padding: 0px 0;}
.nav li.active { background-color: #ccc; }
.nav li.active a { color: #333; }
.nav span { font-size: 12px; font-weight: bold; }

.form-group.outlined {
    border: 1px solid #eee;
    padding: 15px 10px;
}

h1{
    font-weight:normal;
    font-size:20px;
}

.page-header h1 span {
    color: #2c435e;
    font-size:26px;
}

.navbar {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    background-color: #3b516b;
    display: flex;
    flex-direction: column;
    height:100%;
    line-height: 30px;
    padding:0.5rem 0;
    position: relative;
}

.navbar-nav a {
    color: #fff;
    margin-left: 10px;
	display: block;
}

.navbar-nav.float-right a {
    color: #fff;
    margin-left: 10px;
	display: inline;
}

.nav-tabs .fa {
	margin-top: 3px;
	color: blue;
}

.nav span{
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    display: block;
    width: 85%;
    border-bottom: 1px solid #6a7e96;
    margin-left: 6%;
    padding-left: 4px;
    margin-bottom:4px;
}


.nav_divider{
    background-color:#6a7e96;
    height:1px;
    width:90%;
    margin:10px 0 10px 10px;
}


/* changed to -20 with new version of bootstrap */

.navbar-nav{
    padding:10px 0px;
    width:202px;
}

.navbar-nav li{
    padding:3px 10px 3px 15px;
}

.navbar-nav li:hover{
    text-decoration:none;
    background-color:#2c435e;
}
.navbar-nav li:hover a{
    color:#ffffff;
    text-decoration:none;
}
.navbar-nav li.active{
    background-color:#2c435e;
}
.navbar-nav li.active a{
    color:#ffffff;
}

li.nav-item.active {
	height: 46px;
	background-color: white;
}

.navbar-nav .subnav ul{
    background-color:#2c435e;
    margin-left:0px;
    padding-left:15px;
    padding-bottom: 10px;
}
.navbar-nav .subnav li{
    list-style:none;
}
.navbar-nav .subnav li:hover{
    text-decoration:underline;
}

.slidenav{
     height:100vh;
     display:none;
}
.subnavfa{
    margin-left:4px !important;
}
.subnav_title {
    cursor:pointer;
}

.navbar .fa-angle-left {
    margin-right:5px;
}

/*.navbar-nav li.active::after {
    content: "";
    position: relative;
    float:right;
    right: -26px;
    width: 10px;
    height: 10px;
    top: 14px;
    background-color: #F3F4F6;
    -webkit-transform: skew(40deg);
    -moz-transform: skew(40deg);
    -o-transform: skew(40deg);
    -ms-transform: skew(40deg);
    transform: skew(40deg);
}
.navbar-nav li.active::before {
    content: "";
    position: relative;
    float:right;
    right: -16px;
    width: 10px;
    height: 10px;
    top: 0px; // wad 5
    background-color: #F3F4F6;
    -webkit-transform: skew(-40deg);
    -moz-transform: skew(-40deg);
    -o-transform: skew(-40deg);
    -ms-transform: skew(-40deg);
    transform: skew(-40deg);
}*/




/*   Tables and Record Lists Misc.   */
.table thead th{
    border-top: none;
	vertical-align: middle;
    text-transform: capitalize;
}

.table th, .table td {
    vertical-align: top;
	/*border-top: 1px solid #E3E7E8; JK 3/13/2018: May want to use this in the future to darken borders*/
	/* vertical-align: middle; JK 3/1/2018: May want to put this back */
    &.break-word {
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
    }
}

table tr.grey-header th {
	background-color: #efefef;
}

.nowrap {
	white-space: nowrap;
}

.long-wrap-container {
	width: 700px;
	word-wrap: break-word;
}

table.log td label {
	margin-bottom: 0px;
}

table th.column-actions {
    min-width: 130px;
}

.table-bordered {
    border: 1px solid #E2E2E2;
}

.table-bordered.white {
   background-color: #ffffff;
}

.table-bordered th, 
.table-bordered td {
	border: 1px solid #E2E2E2;
}

/*
           Individual Table Styles
*/

table th.dis-opt-left-cell {
	width: 290px; /* Used to make all left cells the same width on Display Options page */
}


.btn-succes{
    background-color: #2AB27B;
}

.btn-danger{
    background-color: #EB4D5C;
}

.btn-primary{
    background-color: #4990E2;
}

.btn-warning{
    background-color: #E7BD5E;
}

#app-layout {
    background-color: #F3F4F6;
    height: 100%;
}

.header-bar{
    -webkit-box-shadow: -1px 10px 4px -10px rgba(0,0,0,0.53);
    -moz-box-shadow: -1px 10px 4px -10px rgba(0,0,0,0.53);
    box-shadow: -1px 10px 4px -10px rgba(0,0,0,0.53);
    height: 90px;
    color: #a3a3a3;
    background-color:#ffffff;
    padding-top:25px;
}

.btn-secondary{
    font-size: 12px;
    color:#292b2c !important;
}

.header-bar .col-sm-7 .nav-item{
    font-size:15px;
    color:#292b2c !important;
}

.header-bar .navbar-nav{
    padding:5px 4px;
}

 .btn-secondary{
     background-color: #F3F4F6 !important;
 }


.header-bar .homelink{
    font-size:18px;
    font-weight:bold;
    color:#A3A3A3;
    text-transform:uppercase;
    margin-left:10px;
}
.header-bar .homelink img{
    max-width:150px;
    margin-left:5px;
}

.header-bar .nav-item:hover{
    background:none important;
}

.navbar-brand .homelink:hover{
    color:#3b516b;
}


.float-right .nav-item:hover{
    background-color:#ffffff;
}

.float-right .btn-secondary:hover{
    color:#333333;
}

#app {
    padding: 20px 30px 50px 30px;
}

/*
.card a{
    color:#3b516b;
    text-decoration:none;
    font-size:16px;
}
.card a:hover{
    color:#2c435e;
}
*/
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f7f7f9;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header a{
    /* font-size:12px; */
}

#app .tscard{
    background-color:#ffffff;
    padding:30px 20px 30px 20px;
    border:1px solid rgba(0, 0, 0, 0.15);
    margin-left:0px;
    border-radius: 3px;
}

.outlined{
    background-color: #FBFBFB;
}
.connectedSortable{
    background-color:#ffffff;
}

/* tabs */

.nav-tabs{
    font-size: 14px;
    border-bottom:0px;
	height: 34px;
 }

.nav-tabs li.active {
	border:1px solid rgba(0, 0, 0, 0.15);
    background-color: white;
    z-index:4;
    color:#999999;
    height:36px;
    border-bottom: 2px solid #ffffff !important;
    border-radius: 3px;
}

.nav-tabs li:not(:first-child).active {
	margin-left: 4px;
}

.nav-tabs .nav-link{
    color:#4990E2;
    font-size: 13px;
    border: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;}

.nav-tabs .nav-link:hover{
    color:#2c435e;
}

.nav-tabs .active{
    z-index:4;
    color:#999999;
}

.nav .nav-item:hover{
    background-color:none !important;
}



.pagination{
    float:right;
}

/*   Forms and Inputs   */

label.required:before {
    content: '*';
}

span.help-block {
    color: $danger;
	display: block;
	margin-top: 4px;
}

input[type="checkbox"]{
    transform:scale(1.5, 1.5);
	margin-left:3px;
}

.form-check-label{
    margin-right:5px;
    padding-left:0px;
}

.form-check{
    margin-bottom:0rem;
}

.checkbox-help-text {
	display: block;
	margin-top: 27px;
}


/*   template images & asides   */
img.uploaded {
    max-height:200px;
    max-width:200px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}



.alert-info{
    margin-top: 20px !important;
}

.how-to-directions {
	margin-bottom: 25px;
}


.empty-set{
    background-color:#F3F4F6;
    color: #a3a3a3;
    border:1px solid rgba(0, 0, 0, 0.15);
    font-weight:normal;
    font-size:16px;
}



/*
            Button layout formatting
*/

.top-right-buttons {
	text-align: right;
	margin-bottom: 5px;
}



/*
            Button Styles
*/

.btn-cancel {
   background-color: #F3F4F6;
   border-color: #adadad;
   color: #292B2C !important;
   margin-right: 3px;
}

.btn-cancel:hover {
   background-color: #ECEDF0;
}




/*
			Headers
*/

h5 {
	margin-bottom: 15px;
}

h4 {
	font-size: 1.4rem;
	margin-bottom: 15px;
}



/*
           Individual Margin Styles
*/

.delete-question {
	margin-bottom: 20px;
}

.lg-bot-marg {
	margin-bottom: 40px;
}



/*
           Individual Element Styles
*/

#display_status_PD_note {
	margin-top: 10px;
	font-size: 12.8px !important;
	display: block;
}

.developed-by {
	position: absolute;
	bottom: 13px;
	right: 30px;
	text-align: right;
	font-size: .85rem;
	margin-top: 15px;
	color: #666666;
}


/*
            Code formatting
*/
pre.formatted-json {
 font-size: 0.8em;
}

